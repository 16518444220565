import axios from '../libs/axios';
// 地图列表
const mapList = (data) => {
  return axios.get('/api/trd/map/list', data);
};

// 新增/修改
const mapAddOrEdit = (data) => {
  return axios.post('/api/trd/map/save', data);
};

// 信息
const mapInfo = (id) => {
  return axios.post(`/api/trd/map/info/${id}`);
};

// 删除
const mapDelete = (data) => {
  return axios.post('/api/trd/map/delete', data);
};

export {
  mapList,
  mapInfo,
  mapAddOrEdit,
  mapDelete,
};

<template>
  <div class="base-content">
      <query-frame
        :pageVisible="false"
        :searchVisible="false"
        @on-add="handleAdd"
        @on-page-change="handlePageChange"
        @on-size-change="handleSizeChange"
      >
      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>
  </div>
</template>

<script>
import queryMixin from '@/mixin/queryMixin';
import { mapList, mapDelete } from '@/api/map';

export default {
  name: 'mapTrackType',
  mixins: [queryMixin],
  props: {},
  data() {
    return {
      // 默认展示表格
      columns: [
        {
          title: '序号',
          width: 200,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '地图名称',
          dataIndex: 'mapTrackName',
          key: 'mapTrackName',
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 300,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return (
              <section>
                <a-button type="link" size="small" onClick={ () => this.handleDelete(row) }>删除</a-button>
              </section>
            );
          }
        }
      ],
      dictOptions: {},
      dataList: []
    };
  },
  created() {
    this.handleQuery();
  },
  methods: {
    // 获取列表
    getData() {
      this.dataList = [];
      mapList(this.params).then((data) => {
        data.forEach((item) => {
          item.key = item.mapTrackId;
        });
        this.dataList = data;
        // console.log(this.dataList)
      });
    },

    // 添加
    handleAdd() {
      this.$router.push({
        name: 'mapTrackTypeAdd'
      });
    },
    // 删除
    handleDelete(row) {
      const self = this;
      this.$confirm({
        title: '删除地图',
        content: '此操作将永远删除该地图，您确定要这么做吗？',
        okType: 'danger',
        onOk() {
          return new Promise((resolve, reject) => {
            const params = {
              ...row
            };
            mapDelete(params).then(() => {
              self.$message.success('删除成功');
              self.getData();
              resolve();
            }).catch(() => {
              reject();
            });
          }).catch((e) => console.log(e));
        },
        onCancel() {},
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
